.key-transactions {
  padding: 1rem;

  .main-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .main-title {
    margin-bottom: 1rem;
  }

  .key-title {
    margin-bottom: 1rem;
    color: #1677ff;
    font-size: 0.875rem;
  }

  .header-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .table-row {
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }

  .cell-width-30 {
    width: 30%;
    text-align: left;

    @media (max-width: 600px) {
      width: auto;
    }
  }

  .cell-width-25 {
    width: 25%;
    text-align: left;

    @media (max-width: 600px) {
      width: auto;
    }
  }

  .cell-width-45 {
    width: 45%;
    text-align: left;

    @media (max-width: 600px) {
      width: auto;
    }
  }

  .content-wrapper {
    display: flex;
    gap: 1rem;
  }

  .left-column {
    flex: 1;
  }

  .paper-section {
    margin-bottom: 1rem;
  }

  .right-column {
    padding: 1rem;
    background: white;
    height: fit-content;
    border-radius: 1rem;
  }

  .purchase-price,
  .collapsed-section {
    margin-bottom: 1rem;
  }

  .custom-radio-group .ant-radio-inner {
    border: 2px solid #1890ff;
    background-color: transparent;
  }

  .custom-radio-group .ant-radio-checked .ant-radio-inner {
    border: 2px solid #1890ff;
  }

  .custom-radio-group .ant-radio-checked .ant-radio-inner::after {
    top: 4px;
    left: 4px;
    width: 20px;
    height: 20px;
    background-color: #1890ff;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }

  .documents {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    .label {
      font-weight: 500;
      border-bottom: none;
    }

    .value {
      border-bottom: none;
    }

    .source {
      color: #757575;
      border-bottom: none;
    }

    .actions {
      width: 3rem;
      text-align: right;
    }

    .warning {
      padding-top: 0;
    }

    .divider {
      padding: 0;
    }
  }

  .timeline-event {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;

    .event-date {
      min-width: 5rem;
      margin-right: 1rem;
    }

    .event-description {
      flex-grow: 1;
    }

    .document-icon {
      margin-left: 0.5rem;
    }
  }
}
