// ContactsList.scss
.contacts-list {
  padding: 1rem;
  gap : 1rem;
  display: flex;
  flex-direction: column;

  .contact-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .contact-card {
    width: 100%;
  }

  .contact-info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .contact-info {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }
  }
}

// Media query for larger screens
@media (min-width: 768px) {
  .contacts-list {
    .contact-cards {
      max-width: 50rem;
    }
  }
}
