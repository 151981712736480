.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #edeff5;
  padding: 1.5rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  z-index: 1000;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__text {
    color: #838391;
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
  }
}
