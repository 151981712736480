.resign-primary-admin-modal {
  .ant-modal-content {
    border-radius: 0.5rem;
  }

  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 1rem;
  }

  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .ant-icon {
      margin-right: 1rem;
      color: #8c8c8c;
    }

    .full-width {
      flex: 1;
    }
  }

  .ant-select-selection-item {
    font-weight: 500;
  }

  .ant-modal-footer {
    border-top: none;

    .ant-btn + .ant-btn {
      margin-left: 0.5rem;
    }
  }
}
