.upload-dragger {
  width: 100%;
  margin-bottom: 1rem;
}

.file-preview {
  height: 68px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1rem;
  border: 1px solid #e7e7e7;
  padding-left: 1rem;
  border-radius: 12px;

  .icon {
    height: 16px;
    width: 16px;
    margin-right: 0.75rem;
  }

  .file-thumbnail {
    width: 36px;
    height: 36px;
    margin-right: 1rem;
  }

  .file-info {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-left: 0.75rem;
    width: 400px;
    max-width: 400px;
  }

  .file-name {
    font-size: 14px;
  }

  .file-size {
    font-size: 12px;
  }

  .cross-icon {
    cursor: pointer;
  }
}

.dropdown-group {
  display: flex;
  flex-direction: column;

  .dropdown-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    gap: 1.5rem;

    > div {
      width: -webkit-fill-available;
    }
  }
}

.already-existing {
  position: relative;
  font-weight: 500;
  padding-left: 10px; /* Adjust padding as needed */
  margin-top: 10px;
  margin-bottom: 10px
}

.already-existing::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px; /* Adjust width as needed */
  background-color: red;
}
