.document-list {
  padding: 1.5rem;

  .header {
    margin-bottom: 1rem;
  }

  .actions-cell {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 48rem) {
    .document-table {
      .table-head-custom {
        display: none;
      }
      .table-body-custom {
        display: flex;
        flex-direction: column;

        tr {
          display: flex;
          flex-direction: column;
          padding: 1rem;

          td {
            border: none;
            padding: 0.5rem 0;
          }
        }
      }
    }
  }
}
