@import '../../../components/common/variables.scss';

.task-module {
  padding: 1rem;
}

.task-table {
  .ant-table {
    &-thead > tr > th {
      background: #fafafa;
      font-weight: 600;
    }

    &-row {
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.task-status {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  line-height: 1;

  &--Pending {
    background-color: #e6f4ff;
    color: #1890ff;
  }

  &--Failed {
    background-color: #fff2e8;
    color: #fa541c;
  }

  &--Incomplete {
    background-color: #fffbe6;
    color: #faad14;
  }

  &--Completed {
    background-color: #f6ffed;
    color: #52c41a;
  }

  &--Processing {
    background-color: #f5f5f5;
    color: #8c8c8c;
  }

  &--Cancelled {
    background-color: #fff1f0;
    color: #f5222d;
  }

  &--AutoCancelled {
    background-color: #fff1f0;
    color: #f5222d;
  }
}

.task-action-send {
  .anticon {
    color: #52c41a;
  }
}

.task-action-cancel {
  .anticon {
    color: #ff4d4f;
  }
}

// Override disabled state color
.ant-dropdown-menu-item-disabled.task-action-cancel {
  .anticon {
    color: rgba(0, 0, 0, 0.25);
  }
}
