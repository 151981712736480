.request-signature {
  padding: 1.5rem;
  background-color: #fff;
  min-height: 100vh;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f0f0f0;

    .ant-typography {
      margin: 0;
      font-weight: 500;
    }
  }

  &__actions {
    display: flex;
    gap: 0.75rem;

    .ant-btn {
      height: 2.5rem;
      padding: 0 1.5rem;
      border-radius: 1.5rem;
      font-weight: 500;
    }
  }

  &__content {
    margin: 0 auto;
  }

  &__section {
    margin-bottom: 1.5rem;
    background: #fff;
    border-radius: 0.5rem;

    .ant-typography {
      margin-bottom: 1rem;
      font-weight: 500;
    }
  }

  .selected-files {
    border: 1px solid #f0f0f0;
    border-radius: 0.75rem;
    background: #f4f4f4;

    .file-item {
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      font-size: 0.875rem;

      &:first-child {
        padding-top: 1rem;
      }

      &:last-child {
        padding-bottom: 1rem;
      }

      &__icon {
        color: #999999;
        font-size: 1rem;
        margin-right: 0.75rem;
      }

      &__name {
        flex: 1;
        color: #4a4a4a;
      }

      &__remove {
        color: #999999;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .sequence-badge {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 4px;
    padding: 2px 8px;
    margin-right: 8px;
    font-size: 12px;
    color: #1890ff;
    width: 5.5rem;

    &.parallel {
      background-color: #f6ffed;
      border-color: #b7eb8f;
      color: #52c41a;
    }
  }

  .recipients-list {
    margin-bottom: 1.5rem;

    .recipient-item {
      background: #fff;
      border: 1px solid #f0f0f0;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      transition: all 0.2s ease;

      &:hover {
        border-color: #d9d9d9;
        box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.04);
      }

      &__row {
        display: grid;
        align-items: center;
        gap: 1rem;
        grid-template-columns:
          minmax(120px, auto) minmax(120px, auto) minmax(150px, 1fr)
          minmax(150px, 1fr) auto;
      }

      &__role-select {
        width: 8.75rem;
      }

      .role-badge {
        display: inline-flex;
        align-items: center;
        // justify-content: center;
        // padding: 0.25rem 0.75rem;
        border-radius: 1rem;
        font-size: 0.8125rem;
        font-weight: 500;
        // width: fit-content;
      }

      &__name,
      &__email {
        color: #262626;
        font-size: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        min-width: 150px;
      }

      &__actions {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-left: auto;
        flex-shrink: 0;

        .ant-btn {
          padding: 0.25rem 0.75rem;

          &-text {
            color: #8c8c8c;

            &:hover {
              background: transparent;
            }

            .anticon {
              font-size: 1rem;
            }
          }

          &-text:hover {
            color: #1677ff;
          }

          &-text.delete-button:hover {
            color: #ff4d4f;
          }

          &-primary {
            background: #1677ff;

            &:hover {
              background: #4096ff;
            }
          }
        }
      }

      .ant-input,
      .ant-select {
        &:hover,
        &:focus {
          border-color: #1677ff;
        }
      }

      .ant-select-focused .ant-select-selector {
        border-color: #1677ff !important;
      }
    }
  }

  .add-recipient {
    height: 2.5rem;
    padding: 0 1.5rem;
    border-radius: 1.5rem;
    font-weight: 500;
  }
}

@media (max-width: 48rem) {
  .request-signature {
    .recipient-item {
      &__row {
        grid-template-columns: 1fr !important;
        gap: 0.75rem !important;
      }

      &__actions {
        justify-content: flex-start;
        padding-top: 0.5rem;
        margin-top: 0.5rem;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
}
