@import '../../components/common/variables.scss';

.landing-page {
  min-height: 100vh;
  overflow-x: hidden;
  background: #f2f8ff;
  .announcement-bar {
    background: linear-gradient(90deg, #1677ff 0%, #3dcf85 100%);
    color: white;
    padding: 12px 24px;
    text-align: center;
    position: relative;
    z-index: 100;

    p {
      margin: 0;
      font-size: 14px;

      .emoji {
        margin-left: 12px;
      }

      .read-more {
        color: white;
        text-decoration: none;
        margin-left: 8px;
        font-weight: 500;
        transition: all 0.3s ease;

        &:hover {
          text-decoration: underline;
          opacity: 0.9;
        }
      }
    }
  }

  .hero-section {
    position: relative;
    min-height: 100vh;
    // background: linear-gradient(135deg, #1677ff 0%, #0052cc 100%);
    overflow: hidden;
    z-index: 1;

    .hero-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
    }

    .hero-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }

    .hero-content-wrapper {
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }

    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 48px;
      background: transparent;
      backdrop-filter: blur(10px);
      position: relative;

      .nav-links {
        display: flex;
        gap: 32px;

        a {
          color: #1a1a1a;
          text-decoration: none;
          font-weight: 500;
          transition: all 0.3s ease;

          &:hover {
            opacity: 0.8;
            transform: translateY(-1px);
          }
        }
      }

      .nav-buttons {
        display: flex;
        gap: 16px;
        align-items: center;

        .login-btn {
          color: #1a1a1a;
          text-decoration: none;
          font-weight: 500;
          transition: all 0.3s ease;

          &:hover {
            background: rgba(255, 255, 255, 0.1);
          }
        }

        .demo-btn {
          border-radius: 1rem;
          background: #1677ff;
          color: #fff;
          text-decoration: none;
          padding: 8px 20px;
          font-weight: 500;
          transition: all 0.3s ease;

          &:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 12px rgba(255, 255, 255, 0.2);
          }
        }
      }
    }

    .hero {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex: 1;

      .hero-content {
        width: -webkit-fill-available;
        .hero-image {
          scale : 0.9;
          margin-top: 2rem;
          margin-bottom: 2rem;
          width: 100%;
          display: flex;
          justify-content: center;

          .mobile-hero-cards {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
          }

          .hero-card {
            height: auto;
            width: -webkit-fill-available;
          }

          .centered-hero-image {
            max-width: 80%;
            height: auto;
            padding: 1rem;
          }
        }

        h1 {
          font-size: 56px;
          line-height: 1.2;
          margin-bottom: 24px;
          color: #1a1a1a;
          font-weight: 700;

          .highlight {
            color: #1677ff;
            position: relative;
          }
        }

        p {
          font-size: 20px;
          color: #1a1a1a;
          margin-bottom: 40px;
        }

        .cta-button {
          display: inline-block;
          border-radius: 32px;
          background: #1677ff;
          color: #fff;
          text-decoration: none;
          padding: 16px 32px;
          font-weight: 500;
          font-size: 18px;
          transition: all 0.3s ease;

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 8px 24px rgba(255, 255, 255, 0.15);
          }
        }
      }
    }
  }

  .features-wrapper {
    position: relative;
    margin-top: -100px; // Adjust this value to control how much it overlaps
    z-index: 2;
    padding: 0 20px;
  }

  .features {
    padding: 48px;

    .features-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 48px;
      max-width: 1200px;
      margin: 0 auto;

      .feature-card {
        background: rgba(255, 255, 255, 0.05);
        padding: 48px;
        border-radius: 16px;
        backdrop-filter: blur(10px);
        transition: all 0.3s ease;
        text-align: center;
        color: white;
        border: 1px solid rgba(255, 255, 255, 0.1);

        &:hover {
          transform: translateY(-4px);
          background: rgba(255, 255, 255, 0.1);
          border-color: rgba(255, 255, 255, 0.2);
        }

        .feature-icon {
          width: 80px;
          height: 80px;
          margin: 0 auto 32px;
          filter: brightness(0) invert(1);

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        h3 {
          font-size: 24px;
          margin-bottom: 16px;
          font-weight: 600;
          line-height: 1.4;
        }

        p {
          opacity: 0.9;
          line-height: 1.6;
          font-size: 16px;
        }
      }
    }
  }

  .why-maxhome {
    padding: 120px 48px;
    text-align: center;
    background: #f8faff;

    h2 {
      font-size: 48px;
      color: #1a1a1a;
      margin-bottom: 24px;
      font-weight: 700;

      .highlight {
        color: #1677ff;
      }
    }

    p {
      font-size: 20px;
      color: #666;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  .tasks-section {
    padding: 120px 48px;
    background: #1677ff;
    color: white;

    .tasks-content {
      display: flex;
      align-items: center;
      gap: 64px;
      max-width: 1200px;
      margin: 0 auto;

      .text-content {
        flex: 1;

        h2 {
          font-size: 40px;
          margin-bottom: 32px;
          font-weight: 700;

          .highlight {
            color: #1677ff;
          }
        }

        ul {
          list-style: none;
          padding: 0;
          margin-bottom: 32px;

          li {
            font-size: 18px;
            margin-bottom: 16px;
            padding-left: 28px;
            position: relative;
            opacity: 0.9;

            &::before {
              content: '✓';
              position: absolute;
              left: 0;
              color: #3dcf85;
              font-weight: bold;
            }
          }
        }

        p {
          font-size: 18px;
          line-height: 1.6;
          opacity: 0.9;
        }
      }

      .image-content {
        flex: 1;

        img {
          width: 100%;
          border-radius: 12px;
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .cta-section {
    padding: 48px;
    text-align: center;
    background: #d1e6ff;

    h2 {
      font-size: 48px;
      margin-bottom: 24px;
      font-weight: 700;
      color: #1a1a1a;
    }

    p {
      font-size: 20px;
      margin-bottom: 40px;
      color: #666;
    }

    .cta-button {
      display: inline-block;
      background: #1677ff;
      color: white;
      text-decoration: none;
      padding: 16px 32px;
      border-radius: 32px;
      font-weight: 500;
      font-size: 18px;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 24px rgba(22, 119, 255, 0.25);
        background: #0052cc;
      }
    }
  }

  .footer {
    background: #f8faff;
    padding: 48px;

    .footer-content {
      display: flex;
      justify-content: space-around;
      margin-bottom: 64px;

      .footer-logo {
        margin-bottom: 2rem;
        img {
          height: 32px;
        }
      }

      .footer-links {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;
        color: #666;

        .footer-section {
          h4 {
            color: #1677ff;
            margin-bottom: 16px;
            font-weight: 600;
          }

          a {
            display: block;
            color: #666;
            text-decoration: none;
            margin-bottom: 12px;
            transition: color 0.3s ease;

            &:hover {
              color: #1677ff;
            }
          }
        }
      }
    }

    .footer-bottom {
      text-align: center;
      padding-top: 32px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      p {
        color: #666;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1024px) {
    .hero-section .features .features-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;
    }

    .tasks-section .tasks-content {
      flex-direction: column;
      text-align: center;

      ul li {
        text-align: left;
      }
    }
  }

  // Mobile styles

  @media (max-width: 768px) {
    .hero-section {
      .navbar {
        padding: 20px;
        flex-wrap: wrap;

        .nav-links {
          order: 3;
          width: 100%;
          margin-top: 16px;
          justify-content: center;
        }

        .nav-buttons {
          gap: 8px; // Mobile gap
        }
      }

      .hero {
        padding: 0px;

        .hero-content h1 {
          font-size: 28px;
        }
      }

      .features .features-grid {
        grid-template-columns: 1fr;
        gap: 24px;
      }
    }

    .footer-content {
      grid-template-columns: 1fr !important;

      .footer-links {
        gap: 0px !important;
        grid-template-columns: 1fr !important;
      }
    }
  }
}
