
.high-confidence-row {
  background-color: #CFFAD3; // green
}

.medium-confidence-row {
  background-color: #FFF4E5;  // yellow
}

.low-confidence-row {
  background-color: #FFE5E5; // red
}