.filter-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 11px;
  border: 1px solid #ccc;
  background: white;
  border-radius: 8px;
  width: 7rem;
  height: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }

  &__label {
    margin-left: 8px;
    margin-right: 8px;
    color: #344054;
    font-size: 14px;
    font-weight: 600;
  }

  &__count {
    background-color: #e5f0ff;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    margin-right: 5px;
  }

  &__close-icon {
    position: absolute;
    padding: 4px;
    top: -20px;
    right: 0px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }

    &-button {
      background-color: #eb3c3c;
      color: white;
      border-radius: 100%;
    }
  }
}
