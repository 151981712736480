.error-card {
  cursor: pointer;
  margin-bottom: 0.5rem;
  border-color: white;
  color: black;
  background-color: white;
  transition: box-shadow 0.3s, background-color 0.3s;

  &.hovered {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  &.selected {
    border-color: #2f78eb;
  }

  &.optional {
    &.hovered {
      background-color: #e6f4ff;
    }
  }

  &.required {
    &.hovered {
      background-color: #fff1f0;
    }
  }
}

.error-card-header {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.error-card-title {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 1rem;
}

.error-card-severity {
  margin-left: auto;
}

.error-card-description {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 400;
}

.error-card-actions {
  width: 100%;
  display: flex;
}

.error-card-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0.25rem;
  background-color: white;
  color: black;
  transition: background-color 0.3s, color 0.3s;

  &.fix {
    background-color: #2f78eb;
    color: white;
  }

  &.ignore {
    background-color: #2f78eb;
    color: white;
  }
}

.check-icon {
  margin-left: 0.25rem;
  height: 1.25rem;
}

.flag-button {
  margin-left: 0.25rem;
  color: black;
}