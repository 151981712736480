/* FILEPATH: /E:/Work/maxhome/webapp/src/index.css */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Reset Tailwind's default box styles */
*:not([class*='tw-']),
*:not([class*='tw-'])::before,
*:not([class*='tw-'])::after {
  box-sizing: initial;
}

/* Override Tailwind's list reset styles */
ol,
ul,
menu {
  list-style: revert;
  margin: revert;
  padding: revert;
}

/* Set default fonts */
:root {
  --font-heading: 'Poppins', sans-serif;
  --font-body: 'Inter', sans-serif;
}

/* BEGIN: Style scrollbar */
/* Width and height of the scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track (background) of the scrollbar */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle (thumb) of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

/* On hover, style the handle */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* In your CSS file */
.hideNumberInputArrows::-webkit-inner-spin-button,
.hideNumberInputArrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hideNumberInputArrows {
  -moz-appearance: textfield;
  /* For Firefox */
}

/* END: Style scrollbar */

/* Base styles without custom fonts */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-fonts-enabled {
  font-family: var(--font-body);
}

.custom-fonts-enabled * {
  font-family: var(--font-body) !important;
}

.custom-fonts-enabled h1,
.custom-fonts-enabled h2,
.custom-fonts-enabled h3,
.custom-fonts-enabled h4,
.custom-fonts-enabled h5,
.custom-fonts-enabled h6,
.custom-fonts-enabled [class*='heading'],
.custom-fonts-enabled [class*='title'],
.custom-fonts-enabled [class*='header'],
.custom-fonts-enabled [style*='font-size: 16'],
.custom-fonts-enabled [style*='font-size:16'],
.custom-fonts-enabled [style*='font-size: 17'],
.custom-fonts-enabled [style*='font-size:17'],
.custom-fonts-enabled [style*='font-size: 18'],
.custom-fonts-enabled [style*='font-size:18'],
.custom-fonts-enabled [style*='font-size: 19'],
.custom-fonts-enabled [style*='font-size:19'],
.custom-fonts-enabled [style*='font-size: 2'],
.custom-fonts-enabled [style*='font-size: 3'],
.custom-fonts-enabled [style*='font-size: 4'],
.custom-fonts-enabled [style*='font-size:2'],
.custom-fonts-enabled [style*='font-size:3'],
.custom-fonts-enabled [style*='font-size:4'],
.custom-fonts-enabled .text-lg,
.custom-fonts-enabled .text-xl,
.custom-fonts-enabled .text-2xl,
.custom-fonts-enabled .text-3xl,
.custom-fonts-enabled .text-4xl,
.custom-fonts-enabled .text-5xl,
.custom-fonts-enabled .text-6xl,
.custom-fonts-enabled .text-7xl,
.custom-fonts-enabled .text-8xl,
.custom-fonts-enabled .text-9xl {
  font-family: var(--font-heading) !important;
}

.ant-table {
  font-size: 13px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
