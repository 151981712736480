.remarks-drawer {
  .remarks-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 0;
  }

  .remarks-list {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 1rem;

    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 12.5rem;
    }

    .remark-item {
      padding: 0.75rem;
      border-radius: 0.25rem;
      margin-bottom: 0.5rem;
      background: #fafafa;

      .remark-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.25rem;

        .ant-typography {
          font-size: 0.75rem;
        }
      }

      .remark-content {
        white-space: pre-wrap;
        word-break: break-word;
        line-height: 1.5;

        .mention {
          color: #1890ff;
          background-color: #e6f7ff;
          border-radius: 0.125rem;
        }
      }
    }
  }

  .remarks-input {
    border-top: 1px solid #f0f0f0;
    padding-top: 1rem;
    margin-top: auto;

    .textarea-container {
      position: relative;
      margin-bottom: 0.75rem;

      .textarea-highlight-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        padding: 0.5rem;
        font-family: inherit;
        font-size: 0.875rem;
        line-height: 1.5;
        overflow: hidden;
      }

      .textarea-highlight {
        white-space: pre-wrap;
        word-break: break-word;

        span {
          color: #000;
          white-space: pre-wrap;
        }

        .mention-highlight {
          display: inline;
          color: #1890ff;
          background-color: #e6f7ff;
          border-radius: 0.125rem;
          white-space: pre-wrap;
        }
      }

      .remarks-textarea {
        width: -webkit-fill-available;
        height: 8rem;
        padding: 0.5rem;
        border: 1px solid #d9d9d9;
        border-radius: 0.25rem;
        resize: vertical;
        font-family: inherit;
        font-size: 0.875rem;
        line-height: 1.5;
        background-color: transparent;
        position: relative;
        z-index: 2;
        color: transparent;
        caret-color: #000;

        &:focus {
          outline: none;
          border-color: #40a9ff;
          box-shadow: 0 0 0 0.125rem rgba(24, 144, 255, 0.2);
        }

        &::placeholder {
          color: #999;
          opacity: 1;
        }
      }
    }

    .mentions-dropdown {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      background: white;
      border: 1px solid #d9d9d9;
      border-radius: 0.25rem;
      box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.15);
      z-index: 1000;
      max-height: 12.5rem;
      overflow-y: auto;
      margin-bottom: 0.5rem;

      .ant-list {
        padding: 0.25rem 0;
      }

      .ant-list-item {
        padding: 0.5rem 0.75rem;
        cursor: pointer;
        transition: background-color 0.3s;
        margin: 0;
        border: none;

        &:hover {
          background-color: #f5f5f5;
        }

        .ant-list-item-meta {
          align-items: center;
          padding: 0;

          .ant-list-item-meta-avatar {
            margin-right: 0.5rem;

            .anticon {
              color: #bfbfbf;
              font-size: 1rem;
            }
          }

          .ant-list-item-meta-title {
            margin-bottom: 0;
            font-size: 0.875rem;
            line-height: 1.5;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }

    .ant-btn {
      float: right;
      padding: 0 1.5rem;
      height: 2.25rem;
      font-size: 0.875rem;
      border-radius: 1.5rem;

      &.ant-btn-primary {
        background-color: #1890ff;
        border-color: #1890ff;

        &:hover {
          background-color: #40a9ff;
          border-color: #40a9ff;
        }

        &:active {
          background-color: #096dd9;
          border-color: #096dd9;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0.1875rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0.1875rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 0.1875rem;

    &:hover {
      background: #999;
    }
  }

  @media (max-width: 768px) {
    .remarks-input {
      .textarea-container {
        .remarks-textarea {
          font-size: 1rem;
        }
      }

      .mentions-dropdown {
        max-height: 10rem;
      }
    }
  }
}
