.filter-component {
  position: relative;
}

.filter-section {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.select-component {
  width: 100%;
  margin-left: auto;
}

.radio-filter {
  margin-left: auto;
}
