.task-preview-modal {
  .task-preview-modal__email-header {
    margin-bottom: 1.25rem;

    .header-row {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      gap: 0.5rem;

      .ant-typography {
        margin-right: 0.5rem;
      }

      strong {
        min-width: 5rem;
      }

      .edit-button {
        color: #1890ff;

        &:hover {
          color: #40a9ff;
          background: rgba(24, 144, 255, 0.1);
        }
      }

      .edit-field {
        flex: 1;
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .ant-input {
          flex: 1;
        }

        .action-button {
          height: auto;

          &.save {
            color: #52c41a;
            &:hover {
              background: rgba(121, 235, 64, 0.1);
            }
          }

          &.cancel {
            color: #ff4d4f;
            &:hover {
              background: rgba(255, 77, 79, 0.1);
            }
          }
        }
      }
    }
  }

  .task-preview-modal__section {
    margin-bottom: 1.5rem;

    .ant-typography {
      color: #262626;
    }
  }

  .section-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    .navigate-link {
      margin-left: 0.5rem;
      font-size: 0.875rem;
    }
  }

  .task-preview-modal__html-content {
    background: #fafafa;
    border: 0.0625rem solid #f0f0f0;
    border-radius: 0.5rem;

    .html-content-wrapper {
      max-height: 25rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0.375rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 0.1875rem;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
      }
    }
  }

  .task-preview-modal__error {
    background: #fff7e6;
    border: 0.0625rem solid #ffd591;
    border-radius: 0.5rem;

    .error-item {
      display: flex;
      align-items: flex-start;
      padding: 0.5rem;

      &:not(:last-child) {
        border-bottom: 0.0625rem solid #ffd591;
      }

      .error-icon {
        font-size: 1rem;
        margin-right: 0.75rem;
        margin-top: 0.1875rem;
        color: #faad14;
      }

      .error-text {
        color: #434343;
        flex: 1;
        line-height: 1.5;
      }
    }
  }

  .sot-field-editor {
    background: #ffffff;
    border-radius: 0.5rem;

    .sot-field-item {
      margin-bottom: 1rem;
      padding: 1rem;
      border: 0.0625rem solid #f0f0f0;
      border-radius: 0.5rem;
      transition: all 0.3s ease;

      &:hover {
        background-color: #fafafa;
        border-color: #d9d9d9;
        box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.04);
      }

      &:last-child {
        margin-bottom: 0;
      }

      .field-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.75rem;

        .ant-typography {
          color: #262626;
          font-size: 0.875rem;
        }

        .ant-tag {
          margin-right: 0;
          text-transform: uppercase;
          font-size: 0.75rem;
          font-weight: 500;

          &.ant-tag-success {
            background: #f6ffed;
            border-color: #b7eb8f;
            color: #52c41a;
          }

          &.ant-tag-warning {
            background: #fff7e6;
            border-color: #ffd591;
            color: #faad14;
          }
        }
      }

      .field-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 2rem;

        .ant-typography {
          color: #595959;
          flex: 1;
        }

        .edit-button {
          opacity: 0;
          transition: all 0.3s ease;
          padding: 0.25rem 0.5rem;
          height: auto;
          color: #1890ff;

          &:hover {
            color: #40a9ff;
            background: rgba(24, 144, 255, 0.1);
          }
        }
      }

      &:hover .edit-button {
        opacity: 1;
      }

      .edit-field {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .ant-input {
          flex: 1;

          &:hover,
          &:focus {
            border-color: #40a9ff;
            box-shadow: 0 0 0 0.125rem rgba(24, 144, 255, 0.2);
          }
        }

        .action-button {
          height: auto;
          transition: all 0.3s ease;

          &.save {
            color: #52c41a;
            &:hover {
              color: #389e0d;
              background: rgba(82, 196, 26, 0.1);
            }
          }

          &.cancel {
            color: #ff4d4f;
            &:hover {
              color: #cf1322;
              background: rgba(255, 77, 79, 0.1);
            }
          }
        }
      }
    }
  }

  .text-task-editor,
  .checklist-task-editor {
    .ant-btn {
      margin-top: 1rem;
    }
  }
}
