.out-of-office-modal {
  .ant-modal-content {
    border-radius: 0.5rem;
  }

  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 1rem;
  }

  .ant-modal-footer {
    border-top: none;
  }

  .office-selector {
    width: 100%;
    .ant-icon {
      font-size: 1rem;
      color: #8c8c8c;
    }
    .ant-select {
      width: 100%;
    }
  }

  .admin-section {
    position: relative;
    margin-bottom: 1rem;

    .admin-section-header {
      display: flex;
      justify-content: space-between;
    }

    .section-title {
      display: block;
      margin-bottom: 1rem;
    }

    .delete-admin {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      color: #8c8c8c;
    }

    .admin-inputs {
      width: 100%;
    }

    .admin-selector,
    .date-range-selector {
      width: 100%;
      .ant-icon {
        font-size: 1rem;
        color: #8c8c8c;
      }
      .ant-select,
      .ant-picker {
        width: 100%;
      }
    }
  }

  .add-admin-button {
    padding-left: 0;
    margin-top: 0.5rem;
  }

  .ant-divider {
    margin: 1rem 0;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
  }
}
