.floating-remarks-button {
  position: fixed;
  z-index: 1000;

  &.bottom-right {
    bottom: 1.5rem;
    right: 1.5rem;
  }

  &.bottom-left {
    bottom: 1.5rem;
    left: 1.5rem;
  }

  &.top-right {
    top: 1.5rem;
    right: 1.5rem;
  }

  &.top-left {
    top: 1.5rem;
    left: 1.5rem;
  }

  .ant-btn {
    width: auto;
    height: 3rem;
    padding: 0 1.5rem;
    border-radius: 1.5rem;
    background: var(--Primary-Secondary, #003f88);
    box-shadow: 0 0.25rem 1.5rem 0.625rem rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
      transform: scale(1.05);
      transition: transform 0.2s ease;
    }
  }
}
