.office-admin-dashboard {
  height: 100vh;
  width: 100%;
  background-color: #f6f6f6;
  box-sizing: border-box;
}

.dashboard-container {
  height: 100%;
  display: flex;
  box-sizing: border-box;
}

.navbar-container {
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  width: 15rem;
}

.content-container {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  ms-overflow-style: none;
  box-sizing: border-box;
}
